<style scoped>
.media-wrapper {
  width: 642px;
  height: 482px;
  margin: auto;
  position: relative;
  margin-top: 60px;
}
.media-wrapper video, .media-wrapper img {
  width: 642px;
  height: 482px;
  left: 0;
  position: absolute;
}
.instructions {
  color: #fff;
  cursor: pointer;
  margin-top: 50%;
}
area {
  display: block;
  cursor: url("/magnifying_glass.png"), auto;
}
@media screen and (max-width: 992px) {
  .media-wrapper {
    padding-top: 30%;
  }
}
@media only screen and (max-width: 992px) and (orientation: landscape) {
  .media-wrapper {
    width: 321px;
    height: 241px; 
    padding-top: 0;
    margin: 0;
  }
}
</style>
<template>
  <!-- Image Map Generated by http://www.image-map.net/ -->
  <div class="media-wrapper">
    <div 
      v-if="showInstructions"
      class="instructions"
      @click="showIntroGif()"
    >
      CLICK TO START
    </div>
    <img v-show="showImageMap" src="/images/WGBC-Home.png" usemap="#image-map">
    <img v-show="showHelp" src="/images/WGBC-Question.png" usemap="#image-map">
    <img 
      v-show="showGif"
      :src="activeGif"
      @click="hideGif()"
    >
  </div>

<map name="image-map">
    <area target="" alt="CROP" href="#crop" coords="11,43,54,87" shape="rect" @click="playSound('/audio/crop-tool.mp3')">
    <area target="" alt="PAINT" href="#paint" coords="10,140,54,183" shape="rect" @click="playSound('/audio/paint-bucket.mp3')">
    <area target="" alt="HAND" href="#hand" coords="9,224,49,265" shape="rect" @click="playSound('/audio/hand.mp3')">
    <area target="" alt="EYE DROPER" href="#eyedropper" coords="8,289,52,335" shape="rect" @click="playSound('/audio/wand.mp3')">
    <area target="" alt="RAIN DROP" href="#raindrop" coords="9,370,54,418" shape="rect" @click="playSound('/audio/water-drop.mp3')">
    <area target="" alt="SQUARE WITH CIRCLE" href="#squarewithcircle" coords="36,423,70,457" shape="rect" @click="pauseAudio()">
    <area target="" alt="BOTTOM ROW 1" href="#bottom-row-1" coords="99,433,171,468" shape="rect" @click="playSound('/audio/bottom-row-1.mp3')">
    <area target="" alt="BOTTOM ROW 2" href="#bottom-row-2" coords="214,431,318,468" shape="rect" @click="playSound('/audio/bottom-row-2.mp3')">
    <area target="" alt="BOTTOM ROW 3" href="#bottom-row-3" coords="360,432,459,468" shape="rect" @click="playSound('/audio/bottom-row-3.mp3')">
    <area target="" alt="BOTTOM ROW 4" href="#bottom-row-4" coords="492,432,591,468" shape="rect" @click="playSound('/audio/bottom-row-4.mp3')">
    <area target="" alt="QUESTION" href="#question" coords="594,439,626,481" shape="rect" @click="toggleHelp()">
    <area target="" alt="FISH 1" href="#fish-1" coords="8,122,67,86,107,87,133,102,57,133" shape="poly" @click="playGif('/video/fish-1-comp.gif', '/audio/fish.mp3')">
    <area target="" alt="WOW" href="#wow" coords="117,74,172,105" shape="rect" @click="playGif('/video/wow-comp.gif', '/audio/wow.mp3')">
    <area target="" alt="FISH 2" href="#fish-2" coords="147,67,196,81,212,92,252,56,205,40" shape="poly" @click="playGif('/video/fish-2-comp.gif', '/audio/fish.mp3')">
    <area target="" alt="FLAGS" href="#flags" coords="105,259,174,324" shape="rect" @click="playGif('/video/flags-comp.gif', '/audio/flags.mp3')">
    <area target="" alt="BW SCREAM" href="#bw-scream" coords="118,351,173,428" shape="rect" @click="playGif('/video/bw-suits-comp.gif', '/audio/bw-suits.mp3')">
    <area target="" alt="SNOW" href="#snow" coords="173,98,223,190" shape="rect" @click="playGif('/video/snow-comp.gif', '/audio/snow.mp3')">
    <area target="" alt="GRASS" href="#grass" coords="175,267,223,428" shape="rect" @click="playGif('/video/grass-comp.gif', '/audio/grass.mp3')">
    <area target="" alt="NOSE" href="#nose" coords="270,289,307,370" shape="rect" @click="playGif('/video/nose-comp.gif', '/audio/noses.mp3')">
    <area target="" alt="BLUE EYE DROPPER" href="#blue-eyedropper" coords="365,35,449,65" shape="rect" @click="playGif('/video/blue-eye-dropper-comp.gif', '/audio/blue-eye-dropper.mp3')">
    <area target="" alt="YELLOW SQUARES" href="#yellow-square" coords="364,112,449,248" shape="rect" @click="playGif('/video/yellow-squares-comp.gif', '/audio/yellow-squares.mp3')">
    <area target="" alt="GOLD" href="#gold" coords="367,304,449,428" shape="rect" @click="playGif('/video/gold-comp.gif', '')">
    <area target="" alt="PILLS" href="#pills" coords="508,48,588,88" shape="rect" @click="playGif('/video/pills-comp.gif', '/audio/pills.mp3')">
    <area target="" alt="GREEN STREAK" href="#green-streak" coords="511,120,585,210" shape="rect" @click="playGif('/video/green-streak-comp.gif', '/audio/green-streak.mp3')">
    <area target="" alt="LIPS" href="#lips" coords="529,248,584,274" shape="rect" @click="playGif('/video/lips-comp.gif', '/audio/lips.mp3')">
    <area target="" alt="NADA" href="#nada" coords="512,275,540,371" shape="rect" @click="playGif('/video/nada-comp.gif', '')">
    <area target="" alt="SNOW 2" href="#snow-2" coords="590,244,639,429" shape="rect" @click="playGif('/video/snow-comp.gif', '/audio/snow.mp3')">
</map>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      audio: null,
      gifAudio: null,
      showImageMap: false,
      firstLoad: true,
      showInstructions: true,
      showHelp: false,
      showGif: false,
      activeGif: null,
    }
  },
  methods: {
    audioEnded() {},
    playSound(sound) {
      this.hideHelp();
      this.audio.pause();
      this.audio.src = sound;
      this.audio.addEventListener('ended', this.audioEnded, false);
      this.audio.play();
    },
    pauseAudio() {
      this.audio.pause();
      this.gifAudio.pause();
      this.video.src = null;
      this.showGif = false;
    },
    stopVideo() {
      this.gifAudio.pause();
      this.video.src = null;
      this.showGif = false;
      this.showImageMap = true;
    },
    playGif(gif, sound) {
      this.hideHelp();
      this.gifAudio.pause();
      this.gifAudio.src = sound;
      this.gifAudio.play();
      this.showImageMap = false;
      this.showGif = true;
      this.activeGif = gif;
    },
    hideGif() {
      this.gifAudio.pause();
      this.showGif = false;
      this.showImageMap = true;
      this.activeGif = null;
      if(this.firstLoad) {
        this.firstAudioEnded();
      }
    },
    showIntroGif() {
      this.showInstructions = false;
      this.showGif = true;
      this.gifAudio.addEventListener('ended', this.firstAudioEnded, false);
      this.playGif('/video/intro-comp.gif', '/audio/intro.mp3');
    },
    firstAudioEnded() {
      this.firstLoad = false;
      this.hideGif();
      this.gifAudio.removeEventListener('ended', this.firstAudioEnded);
      this.gifAudio.loop = true;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
    },
    hideHelp() {
      this.showHelp = false;
    }
  },
  mounted() {
    this.audio = new Audio();
    this.audio.loop = true;
    this.gifAudio = new Audio();
  }
}
</script>
